import React, { Component,useEffect, useState} from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AuthService from "./services/auth-service";
import { withRouter } from "react-router-dom";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            currentUser: undefined,
            isSidebarOpen: false,
            isFullPageLayout: false
        };
    }

    componentDidMount() {
        this.onRouteChanged();
    }

    render() {
        const { isSidebarOpen, isFullPageLayout, currentUser } = this.state;
        let footerComponent = !this.state.isFullPageLayout;

        return (
            <PageRender
                currentUser={currentUser}
                isSidebarOpen={isSidebarOpen}
                footerComponent={footerComponent}
                isFullPageLayout={isFullPageLayout}
                toggleSidebar={this.toggleSidebar}
                pathname={this.props.location.pathname}
            />
        );
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({ currentUser: user, isAdmin: user.admin });
        }

        const body = document.querySelector("body");
        if (this.props.location.pathname === "/layout/RtlLayout") {
            body.classList.add("rtl");
        } else {
            body.classList.remove("rtl");
        }
        
        const fullPageLayoutRoutes = [
            "/login", 
            "/forgotpassword",
            "/radiodialog",
            "/logout"
        ];
        const isFullPageLayout = fullPageLayoutRoutes.includes(this.props.location.pathname);

        this.setState({ isFullPageLayout });

        const pageBodyWrapper = document.querySelector(".page-body-wrapper");
        if (isFullPageLayout) {
            pageBodyWrapper?.classList.add("full-page-wrapper");
        } else {
            pageBodyWrapper?.classList.remove("full-page-wrapper");
        }
        window.scrollTo(0, 0);
    }
    
    toggleSidebar = () => {
        this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }));
    }; 
}

const PageRender = (props) => {
        const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 992);
        const [isSidebarOpen, setIsSidebarOpen] = useState(false);
        const hideSideBar = () => {
            setIsSidebarOpen(false);
        };
        useEffect(() => {
           const handleResize = () => {
               setIsSmallScreen(window.innerWidth <= 992);
           };
           window.addEventListener("resize", handleResize);

           // Cleanup listener on component unmount
           return () => window.removeEventListener("resize", handleResize);
       }, []);
       
    return (
        <div className="container-scroller" style={{overflow:"scroll"/*Fix the date display problem when calendar crosses the boundry of pages and the page is not scrolling to show the calendar fully.*/}}>
            {!props.isFullPageLayout && (
                <React.Fragment>

                    {isSmallScreen ? (
                            
                        <React.Fragment>
                <nav className="navbar default-layout-navbar col-12 p-0 fixed-top d-flex flex-row navbar-dark bg-dark">
                            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between w-100" style={{background:"unset"}}>
                                
                                <button
                                    className="navbar-toggler d-block d-lg-none"
                                    type="button"
                                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
<div className="mobile-nav-user-profile">
                                <Navbar hideSideBar={hideSideBar} />
                            </div>
                                
                            </div>

                            <div className={`text-white mobile-sidebar ${isSidebarOpen ? "open" : ""}`} style={{background:"#f2f8fe"}}>
                                <Sidebar />
                            </div>
                        </nav>
                
                        
                        

                
                            
                    </React.Fragment>
            ) : (
                <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row navbar-dark bg-dark ">
                                <div className="navbar-menu-wrapper d-flex align-items-stretch" style={{width:"100%"}}>
                                    <Sidebar />
                                    <Navbar />
                                    
                                </div>
                            </nav>
            )}
                
                </React.Fragment>
            )}
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className={props.pathname === "/user" || props.pathname === "/dronemissionDash" || props.pathname === "/droneVFAndControl" ? "" : "content-wrapper"}>
                        <AppRoutes />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(App);

/**
 <nav className="navbar navbar-expand-lg fixed-top d-flex flex-row navbar-dark bg-dark">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={props.toggleSidebar}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className={`collapse navbar-collapse ${props.isSidebarOpen ? "show" : ""}`} id="navbarSupportedContent">
                        <Sidebar isOpen={props.isSidebarOpen} toggleSidebar={props.toggleSidebar} />
                    </div>
                    
                        
                    
                    
                </nav>
                <nav className="navbar default-layout-navbar fixed-top d-flex flex-row navbar-dark bg-dark ">
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                    <Navbar />
                                </div>
                </nav>
 */